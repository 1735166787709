<template>
  <div class="dialog-form">
    <el-dialog v-if="isShow" v-model="isShow" :title="title" width="800px" @close="close">
      <el-button type="primary" @click="openChangeServe">添加一条</el-button>
      <div class="coupons-list" style="margin-top: 10px;">
          <el-table ref="dataTableRef" v-if="isShow" :data="dataList" highlight-current-row height="400px">
            <el-table-column label="星期" prop="discount" align="center">
              <template #default="scope">
                <el-select clearable v-model="scope.row.week" placeholder="请选择" @change="changeSelect">
                  <el-option v-for="item in weekList" :key="item.id" :label="item.name" :value="item.id" :disabled="weekIdList.includes(item.id)"></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="折扣" prop="discount" align="center">
              <template #default="scope">
                <el-input-number :controls="false" v-model="scope.row.discount" :min="1" :max="10" :precision="1"
                    style="width: 80%;"></el-input-number>
                <span style="margin-left: 10px">折</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template #default="scope">
                <el-button @click="handleRemove(scope.$index)" type="danger">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      <template #footer>
        <el-button @click="close">取消</el-button>
        <el-button @click="handleSaveClick()" type="primary">保存</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed, onUnmounted, nextTick, handleError } from 'vue'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {
  },
  components: {
  },
  setup(props, { emit }) {
    const isShow = ref(false)
    const title = ref('限时折扣')
    const dataList = ref([])
    const dataTableRef = ref()
    const weekList = reactive(
      [{
        id:1,
        name:'周一'
      },{
        id:2,
        name:'周二'
      },{
        id:3,
        name:'周三'
      },{
        id:4,
        name:'周四'
      },{
        id:5,
        name:'周五'
      },{
        id:6,
        name:'周六'
      },{
        id:7,
        name:'周日'
      }]
    )
    const open = (data) =>{
      isShow.value = true
      dataList.value = JSON.parse(JSON.stringify(data))
      changeSelect()
    }
    const isDisabled = (selectedValue, optionValue) => {
      return selectedValue === optionValue;
    };
    const close = () =>{
      isShow.value = false
    }
    const weekIdList = ref([])
    const changeSelect = ()=>{
      weekIdList.value = dataList.value.map(item=>{
        return item.week
      })
    }
    const openChangeServe = ()=>{
      dataList.value.push({})
    }
    const handleRemove = (i) =>{
      dataList.value.splice(i,1)
      changeSelect()
    }

    const handleSaveClick = async () => {
      let isHasNoCount = dataList.value.some(item => isNaN(item.discount)||!item.week)
      if (isHasNoCount) {
        ElMessage({
          message: '请检查服务折扣是否填写完整！',
          type: 'warning',
        })
        return
      }
      emit('saveForm', dataList.value)
      close()
    }

    return {
      handleSaveClick,
      isShow,
      title,
      open,
      close,
      dataList,
      dataTableRef,
      openChangeServe,
      handleRemove,
      weekList,
      isDisabled,
      changeSelect,
      weekIdList
    }

  }
})
</script>

<style scoped lang="less">
.dialog-form {
  z-index: 999;

  .teacher {
    display: flex;
    flex-wrap: wrap;

    .active {
      background-color: rgb(255, 162, 68);
      color: white;
    }

    .item {
      border-radius: 6px;
      cursor: pointer;
      padding: 0 10px;
      width: 140px;
      line-height: 45px;
      margin: 0 10px;
      border: 1px solid rgba(217, 217, 217, .8);
    }

  }

  &:deep(.el-scrollbar) {
    padding-right: 20px;
  }



}
</style>
