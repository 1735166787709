<template>
  <div class="extension-dialog">
    <pageDialog
      title="推广"
      width="680px"
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
    >
      <div class="main-content">
        <div class="center">
          <div class="img_box">
            <img :src="ruleForm.link" alt="" />
          </div>
          <el-button @click="downloadCode(ruleForm.link,'会员推广码.jpg')" type="primary">下载二维码</el-button>
        </div>
        <div class="center_right">
          <div class="img_box" ref="poster">
            <img :src="ruleForm.link" alt="" />
          </div>
          <el-button @click="downloadImage" type="primary">下载海报</el-button>
        </div>
      </div>
    </pageDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref,nextTick } from 'vue'
import pageDialog from '@/components/page-dialog/page-dialog.vue'
import { getEqCode } from '@/service/main/member'
import html2canvas from "html2canvas";
import axios from 'axios';
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    itemId: {
      type: String,
      default() {
        return null
      }
    }
  },
  components: {
    pageDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {

    const changeDialogVisible = flag => {
      emit('changeDialogVisible', flag)
    }
    const downloadCode = async (url, fileName) => {
      try {
        const response = await axios({
          url,
          method: 'GET',
          responseType: 'blob'
        });
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    };
    const poster =ref();
    const posterimg = ref();
    const downloadImage = () => {
      //    document.querySelector() document.getElementById
      // html2canvas(绑定的元素,{})
      // 海报图默认是  2倍尺寸
      html2canvas(poster.value,{
          backgroundColor:'#f5f5f5',//海报的背景颜色
          useCORS:true, // 允许跨域 
          width:250, //生成海报的w
          height:390, //生成海报的h    默认是px
      }).then(canvas=>{
          // canvas 其实就是我们所讲的res 的意思 返回报文的意思
          let  baseImg = canvas.toDataURL("image/png");
          posterimg.value =baseImg;
          let save  = document.createElement('a');
          // <a href=''></a>
          save.href = baseImg;
          // 下载的名字
          save.download = '活动推广二维码'
          // 直接回调a的点击事件
          save.click()
      })
    }

    const ruleForm = reactive({
      link: '',

    })

    const initPage=async()=>{
      const res=await getEqCode()
      ruleForm.link=res.data
    }
    initPage()

    return {
      changeDialogVisible,
      ruleForm,
      downloadImage,
      poster,
      posterimg,
      downloadCode
    }

  }
})
</script>

<style scoped lang="less">
.extension-dialog {
  .main-content {
    display: flex;
    justify-content: space-around;
    .center,.center_right{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      // width: 300px;
      align-items: center;
      margin: 0 30px;
      &:deep(.el-button){
        margin-top: 20px;
        width: 150px;
      }
      .img_box{
        width: 150px;
        height: 400px;
        display: flex;
        align-items: center;
        img{
          width: 150px;
          height: 165px;
        }
      }
    }
    .center_right{
      .img_box{
        width: 250px;
        height: 390px !important;
        background: url("https://yizhige.oss-cn-shanghai.aliyuncs.com/yizhige/3d265fa9-e3c3-4b40-9e8e-a9f3a602cf12.jpg")
        no-repeat;
        background-size: 100% 100%;
        img{
          width: 150px;
          height: 165px;
          margin: 50px auto 0;
        }
      }
    }
  }
}
</style>
