<template>
    <div class="container">
      <div class="flex_box">
        <el-form ref="ruleFormRef" label-position="left" :model="ruleForm"
        class="demo-ruleForm">
            <el-form-item prop="status" label="" style="margin: 0 20px;">
                <el-select v-model="ruleForm.status" class="m-2" placeholder="会员状态" clearable>
                    <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id" />
                </el-select>
            </el-form-item>
            <el-form-item prop="keyword" label="">
                <el-input prefix-icon="search" v-model="ruleForm.keyword" placeholder="输入姓名或手机号" clearable></el-input>
            </el-form-item>
            <div class="search-btn">
              <el-button @click="searchClick" size="" type="primary">搜索</el-button>
            </div>
        </el-form>
      </div>
      <el-table :data="tabeleData" style="width: 100%">
        <el-table-column align="center" prop="nickname" label="会员名称/手机号">
            <template #default="{row}">
                {{ row.nickname }}({{ row.phone }})
            </template>
        </el-table-column>
        <el-table-column align="center" prop="status" label="会员状态">
          <template #default="{row}">
            {{statusList[row.status-1].name}}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="expireDate" label="到期时间" />
        <el-table-column align="center" prop="lastConsumptionTime" label="最近一次消费" />
        <el-table-column align="center" prop="consumptionCount" label="消费次数"/>
        <el-table-column align="center" prop="consumptionAmount" label="消费金额（元）">
          <template #default="{row}">
            <p v-if="row.consumptionAmount">¥{{row.consumptionAmount}}</p>
          </template>
        </el-table-column>
      </el-table>
      <div class="el-pagination">
        <el-pagination v-model:currentPage="currentPage.current" v-model:page-size="currentPage.size"
            :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next, jumper" :total="total"
            @size-change="handleSizeChange" @current-change="handleCurrentChange" :pager-count="3" />
      </div>
    </div>
  </template>
  
  <script lang="js">
  import { defineComponent, reactive, ref, onUnmounted ,watch,computed} from 'vue'
  import PageContent from '@/components/page-content2/src/page-content.vue'
  import { useRouter } from 'vue-router'
  import { popup } from '@/utils/pop-up'
  import { ElMessage } from 'element-plus'
  import { getMonthlyMemberList } from '@/service/main/member'
  export default defineComponent({
    props: {
      activeName: {
        type: Number,
        default: 0
      },
    },
    components: {
      PageContent
    },
    setup(props) {
      const router = useRouter()
  
      const statusList = reactive([{id:1,name:'使用中'},{id:2,name:'已过期'},{id:3,name:'已退款'}])
      const tabeleData = ref([])
      const ruleForm = reactive({})
      const total = ref(0)
      const currentPage = reactive({
        current: 1,
        size: 10
      })
      watch(currentPage, () => {
        getOrderList()
      }, {
        deep: true
      })
  
      const searchClick = () =>{
        getOrderList()
      }
      const handleSizeChange = (number) => {
        currentPage.size = number
      }
  
      const handleCurrentChange = (number) => {
        currentPage.current = number
      }
      const getOrderList = async() => {
          let data = await getMonthlyMemberList({...currentPage,...ruleForm})
  
          tabeleData.value = data.data.list
          total.value = Number(data.data.total)
      }
      const initPage = async () => {
          getOrderList()
      }
      initPage()
  
      return {
        statusList,
        ruleForm,
        getOrderList,
        tabeleData,
        handleCurrentChange,
        currentPage,
        handleSizeChange,
        total,
        searchClick
      }
  
    }
  })
  </script>
  
  <style scoped lang="less">
  .container {
    .flex_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    // margin-top: 20px;
    .demo-ruleForm {
      display: flex;
    }

    .el-form-item {
      margin-bottom: 0 !important;
    }

    .search-btn {
      margin-left: 20px;
    }

    .line {
      display: inline-block;
      margin: 0 6px;
    }

    .border {
      color: #ccc;
      padding: 0 6px;
    }

    .example-showcase .el-dropdown-link {
      cursor: pointer;
      color: var(--el-color-primary) !important;
      display: flex;
      align-items: center;
    }

    &:deep(.el-table) {

      // font-size: 12px;
      thead.is-group th.el-table__cell {
        background-color: rgba(239, 239, 239, 1) !important;

        .cell {
          color: rgba(80, 80, 80, 1) !important;
          font-size: 14px !important;
          font-weight: 500 !important;
        }
      }


      .el-table__header-wrapper {

        .el-table__cell {

          background-color: rgba(239, 239, 239, 1);

          .cell {
            color: rgba(80, 80, 80, 1) !important;
            font-size: 14px !important;
            font-weight: 500 !important;
          }
        }

      }

      th.el-table__cell {
        background-color: rgba(239, 239, 239, 1) !important;
      }
    }

    .reserve-project-list {
      .serve_flex {
        display: flex;
      }

      .active {
        background-color: #fff !important;
        color: rgb(133, 88, 250);
        font-weight: 550;
      }

      .category-list {
        width: 100px;

        .item {
          cursor: pointer;
          text-align: center;
          font-size: 14px;
          line-height: 45px;
          background-color: rgb(239, 239, 239);
          border-bottom: 1px solid rgb(255, 255, 255);
          min-width: 100px;
          cursor: pointer;
        }
      }

      .reserve-top-form {
        display: flex;
        justify-content: space-between;
        background-color: rgb(239, 239, 239);
        padding: 10px 20px;

        .reserve-el-select {
          width: 45%;
        }
      }

      &:deep(.serve-list-wrap) {
        padding-right: 10 !important;
      }

      .empty {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    .shop-list {
      width: 520px;
      text-align: center;

      .serve-list {
        .item {
          display: flex;
          height: 40px;
          align-items: center;
          border-bottom: 1px solid #efefef;

          .icon {
            width: 80px;
            display: flex;
            justify-content: center;
            color: #ccc;
          }

          .left {
            //   width: 330px;
            display: flex;
            height: 40px;
            align-items: center;

            .name {
              width: 230px;
            }
          }

          .right {

            .checkbox {
              display: flex;

              div {
                width: 110px;
              }

              .salePrice {
                color: rgb(255, 105, 74);
              }
            }
          }
        }
      }

      .titls {
        display: flex;
        //   margin-bottom: 20px;

        div {
          width: 110px;
          line-height: 40px;
          background-color: rgb(239, 239, 239);
        }
      }

    }

    .el-pagination {
      text-align: right;
      margin-top: 10px;
    }
  }
</style>
  