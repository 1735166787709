<template>
  <div class="container">
    <div class="main-content">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm"
        :size="formSize">
        <div class="title">基础信息</div>
        <el-form-item class="form-input" label="会员名称：" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入会员名称"></el-input>
        </el-form-item>
        <el-form-item class="form-input" label="付费规则：">
          <p style="color: #999;">最多可展示3种付费方案。</p>
        </el-form-item>
        <el-form-item>
          <el-checkbox label="1个月" v-model="ruleForm.enabledMonthMember">
            <p class="checkbox_text">1个月</p>
            <el-form-item>
              <el-input-number v-model="ruleForm.monthAmount" :min="0" :controls="false" class="checkbox_input"
                placeholder="价格" />元
            </el-form-item>
          </el-checkbox>
          <el-checkbox label="3个月" v-model="ruleForm.enabledQuarterMember">
            <p class="checkbox_text">3个月</p>
            <el-form-item>
              <el-input-number v-model="ruleForm.quarterAmount" :min="0" :controls="false" class="checkbox_input"
                placeholder="价格" />元
            </el-form-item>
          </el-checkbox>
          <el-checkbox label="12个月" v-model="ruleForm.enabledYearMember">
            <p class="checkbox_text">12个月</p>
            <el-form-item>
              <el-input-number v-model="ruleForm.yearAmount" :min="0" :controls="false" class="checkbox_input"
                placeholder="价格" />元
            </el-form-item>
          </el-checkbox>
        </el-form-item>
        <div class="title">会员权益</div>
        <div class="gift_list">
          <div class="gift_title">更多权益：</div>
          <div class="gift_box_list">
            <div class="gift_box_item">
              <el-checkbox v-model="ruleForm.enableBirthdayGiftCoupon" size="large" />
              <img :src="ruleForm.birthdayGiftUrl" />
              <div class="gift_box_text">
                <p>生日礼券</p>
                <span
                  @click="openCoupon(1)">{{ruleForm.birthdayGiftCouponList&&ruleForm.birthdayGiftCouponList.length?'已选择('+ruleForm.birthdayGiftCouponList.length+')':'选择优惠券'}}</span>
              </div>
            </div>
            <div class="gift_box_item">
              <el-checkbox v-model="ruleForm.enableServeDiscount" size="large" />
              <img :src="ruleForm.serveDiscountUrl" />
              <div class="gift_box_text">
                <p>服务折扣</p>
                <span
                  @click="openServe">{{ruleForm.serveDiscountList&&ruleForm.serveDiscountList.length?'已选择('+ruleForm.serveDiscountList.length+')':'选择服务'}}</span>
              </div>
            </div>
            <div class="gift_box_item">
              <el-checkbox v-model="ruleForm.enableLimitedTimeDiscount" size="large" />
              <img :src="ruleForm.limitedTimeDiscountUrl" />
              <div class="gift_box_text">
                <p>限时折扣</p>
                <span
                  @click="openWeek">{{ruleForm.limitedTimeDiscountList&&ruleForm.limitedTimeDiscountList.length?'已选择('+ruleForm.limitedTimeDiscountList.length+')':'设置折扣'}}</span>
              </div>
            </div>
            <div class="gift_box_item">
              <el-checkbox disabled size="large" />
              <img src="https://yizhige.oss-cn-shanghai.aliyuncs.com/yizhige/51a90b71-53cf-41b6-a545-04e1500f3923.png" />
              <div class="gift_box_text">
                <p>商品折扣</p>
                <span>
                  <el-input-number :controls="false" style="width: 60px;height: 26px;" size="small" disabled />
                  折
                </span>
              </div>
            </div>
            <div class="gift_box_item" v-for="(item,index) in fixedList" :key="index">
              <el-checkbox v-model="item.enabled" size="large" />
              <img :src="item.url" />
              <div class="gift_box_text">
                <p>{{item.name}}</p>
                <span @click="openDialog(1,index,item)">编辑</span>
                <span style="color: red;margin-left: 10px;" @click="remove(1,index)">删除</span>
              </div>
            </div>
            <div class="gift_box_items" @click="openDialog(1)">
              <el-icon>
                <Plus />
              </el-icon>
              <p>添加权益</p>
            </div>
          </div>
        </div>
        <div class="gift_list no_flex">
          <div class="gift_title">开通礼包：开通/续费会员后立即发放</div>
          <div class="gift_box_list">
            <div class="gift_box_item">
              <el-checkbox v-model="ruleForm.enableOpenMemberPacks" size="large" />
              <img :src="ruleForm.openMemberPacksUrl" />
              <div class="gift_box_text">
                <p>送优惠券</p>
                <span
                  @click="openCoupon(2)">{{ ruleForm.openMemberPacksCouponList && ruleForm.openMemberPacksCouponList.length ? '已选择(' + ruleForm.openMemberPacksCouponList.length + ')' : '选择优惠券' }}</span>
              </div>
            </div>
            <div class="gift_box_item" v-for="(item, index) in upgradeList" :key="index">
              <el-checkbox v-model="item.enabled" size="large" />
              <img :src="item.url" />
              <div class="gift_box_text">
                <p>{{ item.name }}</p>
                <span @click="openDialog(2, index, item)">编辑</span>
                <span style="color: red;margin-left: 10px;" @click="remove(2, index)">删除</span>
              </div>
            </div>
            <div class="gift_box_items" @click="openDialog(2)">
              <el-icon>
                <Plus />
              </el-icon>
              <p>添加权益</p>
            </div>
          </div>
        </div>
        <div class="gift_list no_flex">
          <div class="gift_title">每月礼包：根据购买日期，每月同一时间发放</div>
          <div class="gift_box_list">
            <div class="gift_box_item">
              <el-checkbox v-model="ruleForm.enableMonthPacks" size="large" />
              <img :src="ruleForm.monthPacksUrl" />
              <div class="gift_box_text">
                <p>送优惠券</p>
                <span
                  @click="openCoupon(3)">{{ ruleForm.monthPackCouponList && ruleForm.monthPackCouponList.length ? '已选择(' + ruleForm.monthPackCouponList.length + ')' : '选择优惠券' }}</span>
              </div>
            </div>
            <div class="gift_box_item" v-for="(item, index) in monthlyList" :key="index">
              <el-checkbox v-model="item.enabled" size="large" />
              <img :src="item.url" />
              <div class="gift_box_text">
                <p>{{ item.name }}</p>
                <span @click="openDialog(3, index, item)">编辑</span>
                <span style="color: red;margin-left: 10px;" @click="remove(3, index)">删除</span>
              </div>
            </div>
            <div class="gift_box_items" @click="openDialog(3)">
              <el-icon>
                <Plus />
              </el-icon>
              <p>添加权益</p>
            </div>
          </div>
        </div>
        <el-form-item class="form-input" label="自动续费：" prop="enableAutoRenew">
          <el-radio-group v-model="ruleForm.enableAutoRenew">
            <el-radio :label="true">开启</el-radio>
            <el-radio :label="false">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="form-input" label="用户协议：" prop="userAgreement">
          <page-md-editor class="md-editor" :height="600" @onTextChanged="onTextChanged" @onHtmlChanged="onHtmlChanged">
          </page-md-editor>
        </el-form-item>
      </el-form>
      <div class="bottom">
        <el-button @click="handleSaveClick(ruleFormRef)" type="primary">保存</el-button>
      </div>
    </div>
    <FixedForm ref="FixedForm" @saveForm="saveFixedForm"></FixedForm>
    <SelecdCoupon ref="SelecdCoupon" @saveForm="saveCouponList"></SelecdCoupon>
    <SelecdServe ref="SelecdServe" @saveForm="saveServeList"></SelecdServe>
    <SelecdWeek ref="SelecdWeek" @saveForm="saveWeekList"></SelecdWeek>
  </div>
</template>
  
  <script lang="js">
  import { defineComponent, reactive, ref, watch, computed } from 'vue'
  import { useStore } from 'vuex'
  import pageUpload from '@/components/page-upload/page-upload.vue'
  import UploadImg from '@/components/page-upload/upload-img/upload-img.vue'
  import { useRoute } from 'vue-router'
  import router from '@/router'
  import { ElMessage } from 'element-plus'
  import {getMonthlyMemberSetting,saveMonthlyMemberSetting} from '@/service/main/member'
  import FixedForm from '@/views/main/member/integral/cpn/fixedForm.vue'
  import { popup } from '@/utils/pop-up'
  import PageMdEditor from '@/components/page-md-editor/page-md-editor.vue'
  import emitter from '@/utils/eventbus'
  import SelecdCoupon from '@/views/main/member/integral/cpn/selecdCoupon.vue'
  import SelecdServe from '@/views/main/member/integral/cpn/selecdServe.vue'
  import SelecdWeek from '@/views/main/member/integral/cpn/selecdWeek.vue'
  export default defineComponent({
    props: {
    },
    components: {
      pageUpload,
      UploadImg,
      FixedForm,
      PageMdEditor,
      SelecdCoupon,
      SelecdServe,
      SelecdWeek
    },
    setup(props, { emit }) {
      const route = useRoute()
      const store = useStore()
      const ruleForm = reactive({
        enableBirthdayGiftCoupon:false,//是否启用生日礼券
        enableMonthPacks:false,//是否启用月礼包
        enableServeDiscount:false,//是否启用服务折扣
        enableOpenMemberPacks:false,//是否启用升级礼券
        enableLimitedTimeDiscount:false,//是否启用限时折扣
        birthdayGiftUrl:'',//生日礼券权益封面
        serveDiscountUrl:'',//服务折扣权益封面
        openMemberPacksUrl:'',//开通礼包权益封面
        monthPacksUrl:'',//每月礼包券权益封面
        limitedTimeDiscountUrl:'',//限时折扣权益封面
        birthdayGiftCouponList:[],//生日礼券列表
        openMemberPacksCouponList:[],//开通礼包礼券列表
        monthPackCouponList:[],//每月礼包列表
        serveDiscountList:[],//服务折扣列表
        limitedTimeDiscountList:[],//限时服务配置
      })
      const FixedForm = ref()
      const SelecdCoupon = ref()
      const SelecdServe = ref()
      const SelecdWeek = ref()
      const rules = {
        name: [{ required: true, message: '请输入', trigger: 'blur' }]
      }
      const ruleFormRef = ref()
      const fixedList = ref([])
      const upgradeList = ref([])
      const monthlyList = ref([])
      const openDialog = (type,i,e) =>{
        if (e) {
          FixedForm.value.open({index:i,...e,type})
        } else {
          FixedForm.value.open({type})
        }
      }

      const remove = (type,i) =>{
        popup('确认删除该权益吗?', async() => {
          switch (type) {
            case 1:
              fixedList.value.splice(i,1)
              break;
            case 2:
              upgradeList.value.splice(i,1)
              break;
            case 3:
              monthlyList.value.splice(i,1)
              break;
          
            default:
              break;
          }
        })
      }

      const saveFixedForm = (data) =>{
        if (data.type==1) {
          if (data.index||data.index==0) {
            fixedList.value[data.index] = {enabled:data.enabled,name:data.name,url:data.url}
          }else{
            fixedList.value.push({enabled:false,name:data.name,url:data.url})
          }
        } else if (data.type==2) {
          if (data.index||data.index==0) {
            upgradeList.value[data.index] = {enabled:data.enabled,name:data.name,url:data.url,description:data.description}
          }else{
            upgradeList.value.push({enabled:false,name:data.name,url:data.url,description:data.description})
          }
        } else if (data.type==3){
          if (data.index||data.index==0) {
            monthlyList.value[data.index] = {enabled:data.enabled,name:data.name,url:data.url,description:data.description}
          }else{
            monthlyList.value.push({enabled:false,name:data.name,url:data.url,description:data.description})
          }
        }
        
      }
      const openCoupon = (e) =>{
        let dataList;
        if (e==1) {
          dataList=ruleForm.birthdayGiftCouponList
        }else if (e==2) {
          dataList=ruleForm.openMemberPacksCouponList
        }else if (e==3) {
          dataList=ruleForm.monthPackCouponList
        }
        SelecdCoupon.value.open({dataType:e,dataList})
      }

      const saveCouponList = (data) =>{
        if (data.dataType==1) {
          ruleForm.birthdayGiftCouponList = data.dataList
        }else if (data.dataType==2) {
          ruleForm.openMemberPacksCouponList = data.dataList
        }else if (data.dataType==3) {
          ruleForm.monthPackCouponList = data.dataList
        }
      }
      const openServe = () =>{
        SelecdServe.value.open(ruleForm.serveDiscountList)
      }
      const openWeek = () =>{
        SelecdWeek.value.open(ruleForm.limitedTimeDiscountList)
      }
      const saveServeList = (data) =>{
        ruleForm.serveDiscountList = data
      }
      const saveWeekList = (data) =>{
        ruleForm.limitedTimeDiscountList = data
      }
      const contentText = ref('')
      const contentHtml = ref('')
      const onTextChanged = (value) => {
        contentText.value = value
      }
      const onHtmlChanged = (value) => {
        contentHtml.value = value
      }
  
      const cancel = () =>{
        router.back()
      }
      const initPage = async() => {
        let res = await getMonthlyMemberSetting()
        for (const key in res.data) {
          if (Object.prototype.hasOwnProperty.call(res.data, key)) {
            ruleForm[key] = res.data[key];
          }
        }
        fixedList.value = res.data.memberCustomizeBenefitsList||[]
        upgradeList.value = res.data.openMemberBenefitsList||[]
        monthlyList.value = res.data.monthCustomizeBenefitsList||[]
        ruleForm.birthdayGiftUrl = 'https://yizhige.oss-cn-shanghai.aliyuncs.com/yizhige/4e7e6f7f-b696-4517-b6b6-f243d63de150.png'
        ruleForm.serveDiscountUrl = 'https://yizhige.oss-cn-shanghai.aliyuncs.com/yizhige/d656ad1c-77dd-408c-9c81-d905541e441b.png'
        ruleForm.openMemberPacksUrl = 'https://yizhige.oss-cn-shanghai.aliyuncs.com/yizhige/bb19a37c-5ff5-4e55-943e-98fabb06bf31.png'
        ruleForm.monthPacksUrl = 'https://yizhige.oss-cn-shanghai.aliyuncs.com/yizhige/8284b7e4-6918-4097-8888-5d1f030dc030.png'
        ruleForm.limitedTimeDiscountUrl = 'https://yizhige.oss-cn-shanghai.aliyuncs.com/yizhige/e75a8b8b-af49-4ff7-b572-117d1b9d814e.png'
        emitter.emit('changeUpdateCardCardInfo', res.data.userAgreement)
      }
      initPage()

      // 提取展示消息提示的公共函数
      const showMessage = (message, type) => {
          ElMessage({
              message,
              type
          });
      };

      const handleCreate = async (formData) => {
          try {
              const res = await saveMonthlyMemberSetting(formData);
              if (res.code === 0) {
                  showMessage('保存成功','success');
              } else {
                  showMessage(res.msg, 'warning');
              }
          } catch (error) {
              showMessage('新增操作出现异常，请稍后重试', 'error');
          }
      };
      const handleSaveClick = async (formEl = undefined) => {
          if (!formEl) return;
          const valid = await formEl.validate();
          if (valid) {
              const formData = {
                  combinedFormData: {
                    ...ruleForm,
                      memberCustomizeBenefitsList: fixedList.value,
                      openMemberBenefitsList: upgradeList.value,
                      monthCustomizeBenefitsList: monthlyList.value,
                      userAgreement:contentHtml.value
                  }
              };
              await handleCreate(formData.combinedFormData);
          } else {
              showMessage('请正确填写数据', 'warning');
          }
      };
  
      return {
        rules,
        ruleFormRef,
        ruleForm,
        handleSaveClick,
        cancel,
        fixedList,
        upgradeList,
        monthlyList,
        FixedForm,
        openDialog,
        saveFixedForm,
        remove,
        onTextChanged,
        contentText,
        onHtmlChanged,
        contentHtml,
        openCoupon,
        SelecdCoupon,
        saveCouponList,
        SelecdServe,
        openServe,
        saveServeList,
        SelecdWeek,
        openWeek,
        saveWeekList
      }
  
    }
  })
  </script>
  
  <style scoped lang="less">
  .container {
    min-width: 1080px;

    .main-content {
      background-color: #fff;

      .title {
        position: relative;
        padding-left: 12px;
        margin: 24px 0;
        margin-left: 20px;

        &::before {
          content: "";
          position: absolute;
          top: -2px;
          left: 0;
          width: 6px;
          height: 22px;
          background-color: rgba(42, 130, 228, 1);
        }
      }

      .checkbox_text {
        width: 100px;
        text-align: center;
        margin-bottom: 10px;
      }

      .checkbox_input {
        width: 100px;
        margin-right: 10px;
      }

      .gift_list {
        display: flex;
        margin-left: 32px;
        margin-bottom: 25px;

        .gift_title {
          font-size: 14px;
          padding-top: 10px;
          color: #B0B0B0;
          min-width: 70px;
        }

        .gift_box_list {
          display: flex;
          margin-left: 14px;
          flex-wrap: wrap;

          .gift_box_item {
            background-color: #FBF9FE;
            padding: 18px 15px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            margin-right: 20px;
            cursor: pointer;
            margin-bottom: 10px;

            img {
              width: 40px;
              height: 40px;
              margin: 0 15px;
            }

            .gift_box_text {
              width: 100px;
              line-height: 26px;

              p {
                font-size: 15px;
              }

              span {
                font-size: 13px;
                color: #7948ea;
              }
            }
          }

          .gift_box_items {
            background-color: #FBF9FE;
            // padding: 10px 15px;
            height: 90px;
            width: 224px;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-right: 20px;
            color: #7948ea;
            font-size: 25px;
            cursor: pointer;

            p {
              margin-top: 10px;
              font-size: 14px;
            }
          }
        }
      }

      .no_flex {
        display: block;

        .gift_title {
          margin-bottom: 15px;
          padding-top: 0px;
        }

        .gift_box_list {
          margin-left: 84px;
        }
      }

      .md-editor {
        width: 70%;
        padding: 0 10px 10px 10px;
        background-color: rgba(239, 239, 239, 1);
        // height: 820px;

        &:deep(.md) {
          // height: 820px;
        }

        &:deep(.md-toolbar-wrapper) {
          .md-toolbar-item {
            padding: 0 0;
          }

          .md-toolbar {
            max-width: 340px;
            min-width: 310px;
            flex-wrap: wrap;
            height: auto;
          }

          .md-toolbar-left {
            flex-wrap: wrap;
          }

          .md-toolbar-right {
            margin-bottom: 10px;
          }
        }
      }
    }

    .online-reserve {
      &:deep(.el-input) {
        max-width: 120px;
        margin-left: 10px;
      }
    }

    // &:deep(.el-input-number .el-input__inner) {
    //   text-align: left !important;
    // }

    .tips {
      color: rgba(128, 128, 128, 1);
      font-size: 12px;
    }

    .form-input {

      &:deep(.el-input, .el-select) {
        width: 300px;
      }

      &:deep(.el-select) {
        width: 300px;

        .el-input {
          width: 100%;
        }
      }

      &:deep(.el-input-number) {
        width: 300px;
      }
    }

    .bottom {
      display: flex;
      justify-content: center;
      margin: 50px 0;
    }
  }

  :deep(.el-input--small .el-input__inner) {
    height: 26px;
    line-height: 26px;
  }
</style>
  