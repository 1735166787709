<template>
    <div class="container">
      <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
      <div class="main-content-title">
        <div class="bg">
          <div class="left">
            <div class="border"></div>
            <p>PLUS 会员</p>
          </div>
          <div class="right">
            <p style="margin-right: 20px" @click="paymentRecords">付费记录</p>
            <p @click="promotion">推广</p>
          </div>
        </div>
      </div>
      <div class="main-content">
        <el-tabs v-model="activeName" type="card" class="demo-tabs" @tab-click="handleClick">
          <el-tab-pane label="会员列表" :name="0">
            <MemberList></MemberList>
          </el-tab-pane>
          <el-tab-pane label="权益设置" :name="1">
            <ConfigForm></ConfigForm>
          </el-tab-pane>
          <el-tab-pane label="展示设置" :name="2">
            <ShowConfig></ShowConfig>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <ExtensionDialog
      v-if="dialogVisible"
      :dialogVisible="dialogVisible"
      @changeDialogVisible="changeDialogVisible"
    ></ExtensionDialog>
  </template>
  
  <script lang="js">
  import { defineComponent, reactive, ref } from 'vue'
  import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
  import MemberList from './cpns/MemberList.vue'
  import ConfigForm from './cpns/ConfigForm.vue'
  import ShowConfig from './cpns/ShowConfig.vue'
  import ExtensionDialog from './cpns/ExtensionDialog.vue'
  import { breadcrumbList } from './config'
  import { useRouter } from 'vue-router'
  import { popup } from '@/utils/pop-up'
  import { ElMessage } from 'element-plus'
  
  export default defineComponent({
    props: {
  
    },
    components: {
      Breadcrumb,
      MemberList,
      ConfigForm,
      ShowConfig,
      ExtensionDialog
    },
    setup() {
      const router = useRouter()
      const activeName = ref(0)
      const handleClick = () =>{

      }

      const paymentRecords = () => {
        router.push('/main/member/paid/paymentRecords')
      }

      const promotion = () => {
        dialogVisible.value = true
      }
      const dialogVisible = ref(false)
      const changeDialogVisible = flag => {
        dialogVisible.value = flag
      }
      return {
        breadcrumbList,
        activeName,
        handleClick,
        paymentRecords,
        promotion,
        dialogVisible,
        changeDialogVisible
      }
  
    }
  })
  </script>
  
  <style scoped lang="less">
  .container {
    min-width: 1080px;
  
    .main-content,.main-content-title {
      padding: 15px;
      margin: 20px;
      background-color: #fff;
    }
    .main-content-title {
      .bg{
        background-color: #F9F5FE;
        display: flex;
        padding: 15px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left{
          display: flex;
          align-items: center;
          .border{
            width: 5px;
            height: 20px;
            background-color: #7948ea;
            margin-right: 10px;
          }
        }
        .right{
            color: #7948ea;
            display: flex;
            align-items: center;
            p{
                cursor: pointer;
            }
        }
      }
    }
    
    .main-content{
        min-height: 80vh;
        padding: 30px 20px;
        &:deep(.el-table) {
          th.el-table__cell{
            background-color: rgba(239, 239, 239, 1) !important;
            .cell {
              color: rgba(80, 80, 80, 1) !important;
              font-size: 14px !important;
              font-weight: 500 !important;
            }
          }
          .el-table__header-wrapper {
          
          .el-table__cell {

            background-color: rgba(239, 239, 239, 1);
            // padding: 9px 0 !important;
            // border:1px solid rgb(223, 223, 223);

            .cell {
              color: rgba(80, 80, 80, 1) !important;
              font-size: 14px !important;
              font-weight: 500 !important;
            }
          }

        }
          .cell {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }
        }
    }
  }
  </style>
  
  
  
  
  