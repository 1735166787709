<template>
    <div class="container">
      <div class="flex_box">
        <div class="left">
          入口展示：
        </div>
        <div class="right">
          <p>
            <span>启用PLUS会员</span>
            <el-switch v-model="ruleForm.enabled" />
          </p>
          <span>关闭后，客户不能再购买和续费会员，现有的PLUS会员仍可享受权益至有效期结束</span>
          <div class="bg">
            <div>
              <el-checkbox :disabled="!ruleForm.enabled" v-model="ruleForm.productPageDisplay">商品详情页</el-checkbox>
              <img src="https://yizhige.oss-cn-shanghai.aliyuncs.com/yizhige/6d823366-cd80-45cb-b9a9-53dccb184fcf.png" />
            </div>
            <div>
              <el-checkbox :disabled="!ruleForm.enabled" v-model="ruleForm.userPageDisplay">个人中心</el-checkbox>
              <img src="https://yizhige.oss-cn-shanghai.aliyuncs.com/yizhige/dd3237a4-011f-48b0-823b-1f343e7201fe.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <el-button @click="handleSaveClick()" type="primary">保存</el-button>
      </div>
    </div>
  </template>
  
  <script lang="js">
  import { defineComponent, reactive, ref, onUnmounted ,watch,computed} from 'vue'
  import PageContent from '@/components/page-content2/src/page-content.vue'
  import { useRouter } from 'vue-router'
  import { popup } from '@/utils/pop-up'
  import { ElMessage } from 'element-plus'
  import { saveDisplaySetting,getDisplaySetting } from '@/service/main/member'
  export default defineComponent({
    props: {
    },
    components: {
      PageContent
    },
    setup(props) {
      const router = useRouter()
      const ruleForm = ref({})
      const getOrderList = async() => {
          let data = await getDisplaySetting()
          ruleForm.value = data.data
      }
      const initPage = async () => {
          getOrderList()
      }
      initPage()

      const handleSaveClick = ()=>{
        saveDisplaySetting(ruleForm.value).then(res=>{
          ElMessage({
            message: '保存成功!',
            type: 'success',
          })
        })
      }
  
      return {
        ruleForm,
        handleSaveClick
      }
  
    }
  })
  </script>
  
  <style scoped lang="less">
  .container {
    .bottom {
      display: flex;
      justify-content: center;
      margin: 50px 0;
    }
    .flex_box{
      display: flex;
      width: 90%;
      margin: 20px auto;
      align-items: flex-start;
    
      .left{
        font-size: 16px;
        color: #333;
      }
      .right{
        p{
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          span{
            margin-right: 10px;
            font-size: 16px;
            color: #333;
          }
        }
        span{
          font-size: 12px;
          color: #999;
        }
        .bg{
          background-color: #FBF9FE;
          padding: 18px 15px;
          border-radius: 8px;
          display: flex;
          margin-top: 20px;
          width: 650px;
          justify-content: space-between;
          div{
            display: flex;
            flex-direction: column;
            align-items: center;
            img{
              width: 300px;
              height: 600px;
            }
          }
        }
      }
    }
  }
  </style>
  